"use client"

import { Header, Footer, ContentCard, useAuthInfo, API_BASE_URL, urlFetcher, cref_browse_url, DisplayConceptRef, UserPosts, Follow } from './common';
import Link from 'next/link';
import { useRequireAuth } from './backend_apis';
import { JSXElementConstructor, Key, PromiseLikeOfReactNode, ReactElement, ReactNode, ReactPortal, useState } from 'react';
import { Fragment } from 'react';
import { TopicSelector } from './topic_selector';
import useSWR from 'swr';

function TopicDocs({ topic }: { topic: string }) {
  const { data: docs, error, isLoading } = useSWR(
    `${API_BASE_URL}/doc/topic/${encodeURIComponent(topic)}/3`,
    urlFetcher
  );

  if (isLoading) return <p>Loading docs...</p>;
  if (error) return <p>Error loading docs</p>;

  return (
    <ul>
      {docs && docs.length > 0 ? (
        docs.map((doc: any, index: number) => (
          <li key={index}>
            <Link href={`/doc/${encodeURIComponent(doc._key)}`}>
              {new Date(doc.provenance.createdon).toLocaleDateString()}
            </Link>
            {" "}
            {doc.provenance.creator}
            <br />
            {doc.text.substring(0, 110)}...
          </li>
        ))
      ) : (
        <li>No recent docs</li>
      )}
    </ul>
  );
}

function FollowedUserPosts({ username }: { username: string }) {
  const { data: posts, error, isLoading } = useSWR(
    `${API_BASE_URL}/doc/creator/${encodeURIComponent(username)}/3`,
    urlFetcher
  );

  if (isLoading) return <p>Loading posts...</p>;
  if (error) return <p>Error loading posts</p>;

  return (
    <ul>
      {posts && posts.length > 0 ? (
        posts.map((post: any, index: number) => (
          <li key={index} >
            <Link href={`/doc/${encodeURIComponent(post._key)}`} >
              {post.text.substring(0, 50)}...
            </Link>
          </li>
        ))
      ) : (
        <li>No recent posts</li>
      )}
    </ul>
  );
}

export default function Home() {
  const isAuthenticated = useRequireAuth();

  const { authInfo, authInfoError, authInfoIsLoading } = useAuthInfo();

  const { data: userDetails, error: userDetailsError, isLoading: userDetailsLoading, mutate: userDetailsMutate } = useSWR(
    authInfo && authInfo.username
      ? `${API_BASE_URL}/user/get_details/${encodeURIComponent(authInfo.username)}`
      : null,
    urlFetcher
  );

  const { data: posts, error: postsError, isLoading: postsLoading } = useSWR(
    authInfo && authInfo.username
      ? `${API_BASE_URL}/doc/creator/${encodeURIComponent(authInfo.username)}/10`
      : null,
    urlFetcher
  );

  return (
    !isAuthenticated ? <div>loading...</div> :
    <main className="flex-container">

      <TopicSelector/>

      <div className="flex-container content-cards-container">
        <ContentCard title="Your Communities" collapsible={false}>
          {userDetailsLoading ? (
            <p>Loading your communities...</p>
          ) : userDetailsError ? (
            <p>Error loading your communities.</p>
          ) : userDetails && userDetails.communities.length > 0 ? (
            <ul className="no-bullets">
              {userDetails.communities.map((community: DisplayConceptRef, index: number) => (
                <li key={index}>
                  <Link href={cref_browse_url(community.cref)}>
                    {community.display}
                  </Link>
                  <TopicDocs topic={community.cref} />
                </li>
              ))}
            </ul>
          ) : (
            <p>You have no communities yet.</p>
          )}
        </ContentCard>

        <ContentCard title="Your Follows" collapsible={false}>
          {userDetailsLoading ? (
            <p>Loading your follows...</p>
          ) : userDetailsError ? (
            <p>Error loading your follows.</p>
          ) : userDetails && userDetails.following.length > 0 ? (
            <ul className="no-bullets">
              {userDetails.following.map((follow: Follow, index: number) => (
                <li key={index}>
                  <Link href={`/citizen/${encodeURIComponent(follow.username)}`}>
                    {follow.username}
                  </Link>
                  {follow.topics.length > 0 && (
                  /* TODO: make this pretty */
                  <div>Topics: {follow.topics.map((c: DisplayConceptRef) => c.display).join(', ')} </div> ) }
                  <FollowedUserPosts username={follow.username} />
                </li>
              ))}
            </ul>
          ) : (
            <p>You are not following anyone yet.</p>
          )}
        </ContentCard>

        <ContentCard title="Your Recent Posts" collapsible={false}>
          {postsLoading ? (
            <p>Loading your posts...</p>
          ) : postsError ? (
            <p>Error loading your posts.</p>
          ) : posts && posts.length > 0 ? (
            <UserPosts posts={posts} />
          ) : (
            <p>You have no posts.</p>
          )}
        </ContentCard>

      </div>

    </main>
  )
}
