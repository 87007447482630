// TODO: Rename to auth or something, or put in common.tsx

import useSWR from 'swr';   /* for client-side data fetches */
import {useEffect, useState} from 'react';
import { useRouter } from 'next/navigation';
import { urlFetcher, API_BASE_URL } from './common';

export function useRequireAuth(redirectTo = '/login') {
  const router = useRouter();
  const req_url = API_BASE_URL + '/auth/check';
  const { data, error, isLoading } = useSWR(req_url, urlFetcher);

  useEffect(() => {
    if (isLoading) return;

    if (error || !data?.valid) {
      router.push(redirectTo);
    }
  }, [data, error, isLoading, redirectTo, router]);

  const isAuthenticated = !isLoading && !!data?.valid;

  return isAuthenticated;
}

export async function checkAuth() {
  // TODO: This is a total hack.  We can't use relative urls because
  // this might be running on the server
  // const req_url = API_BASE_URL + '/auth/check';
  const req_url = "http://localhost:3001/auth/check";
  let res = await fetch(req_url, {credentials: 'include'});

  // check if response field 'valid' is true
  return res.ok && !!(await res.json()).valid;
}